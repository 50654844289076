import * as React from "react";
import { withGoogleSheets } from "react-db-google-sheets";

import Typography from "@mui/material/Typography";

function Category(props) {
  const categoryName = props.db.Categories.find(
    (cat) => cat.id === props.catID
  ).name;

  // console.log(categoryName);

  return (
    <Typography
      variant="h5"
      component="h2"
      textAlign="center"
      className="categoryTitle"
      sx={{
        flex: "1 1 100%",
        mt: 3,
        mb: 2,
        columnSpan: "all",
        borderRadius: "4px",
      }}
    >
      {categoryName}
    </Typography>
  );
}

export default withGoogleSheets("Categories")(Category);

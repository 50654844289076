import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#FF9900",
    },
    secondary: {
      main: "#333",
    },
    warning: {
      main: "#F47525",
    },
    error: {
      main: red.A400,
    },
  },
  typography: {
    fontFamily: [
      "panel-sans",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    body1: {
      fontWeight: 300,
    },
    caption: {
      fontWeight: 300,
      lineHeight: 1.1,
    },
  },
});

export default theme;

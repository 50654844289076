import * as React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

export default function Copy() {
  return (
    <Box className="copyright">
      <Divider sx={{ my: 2 }} />
      <Typography variant="body2" color="text.secondary" align="center">
        {"© "}
        <Link color="inherit" href="https://www.butoiuldeaur.ro/">
          Butoiul de Aur
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </Box>
  );
}

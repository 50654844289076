import * as React from "react";
import { withGoogleSheets } from "react-db-google-sheets";

import Typography from "@mui/material/Typography";

function Translations(props) {
  // const translation = props.db.Translations.find(
  //   (cat) => cat.id === props.catID
  // ).name;

  const translations = props.db.Translations;
  const translation = translations.find((item) => item.lang === props.lang)[
    props.text
  ];

  // console.log(translations);

  return <span>{translation}</span>;
}

export default withGoogleSheets("Translations")(Translations);

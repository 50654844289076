import * as React from "react";
import { withGoogleSheets } from "react-db-google-sheets";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Link from "@mui/material/Link";
import { Grid, Fab, Button, ButtonGroup, Divider } from "@mui/material";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import LangIcon from "@mui/icons-material/Public";
import FoodIcon from "@mui/icons-material/Restaurant";
import DrinksIcon from "@mui/icons-material/WineBar";
import BeerIcon from "@mui/icons-material/SportsBar";
import ImageIcon from "@mui/icons-material/Image";
import ImageHideIcon from "@mui/icons-material/HideImage";
import PrintIcon from "@mui/icons-material/Print";

import "./styles.css";

import Flag from "./Flag";
import Copyright from "./Copyright";
import TopNav from "./TopNav";
import Category from "./Category";
import Translations from "./Translations";

const DishType = (props) => {
  switch (props.type) {
    case "hot":
      return (
        <img
          src="/images/hot.svg"
          width="24"
          height="24"
          alt="Hot"
          style={{
            // float: "right",
            marginBottom: 4,
            // marginRight: "-32px",
            verticalAlign: "bottom",
          }}
        />
      );
      break;
    case "vegetarian":
      return (
        <img
          src="/images/vegetarian.svg"
          width="24"
          height="24"
          alt="Vegetarian"
          style={{
            // float: "right",
            marginBottom: 4,
            // marginRight: "-32px",
            verticalAlign: "bottom",
          }}
        />
      );
      break;
    case "Pahar":
      return (
        <img
          src="/images/wineGlass.svg"
          width="32"
          height="32"
          alt="Pahar"
          style={{
            // float: "right",
            marginBottom: 4,
            // marginRight: "-32px",
            verticalAlign: "bottom",
          }}
        />
      );
      break;
    case "Sticla":
      return (
        <img
          src="/images/wineBottle.svg"
          width="32"
          height="32"
          alt="Pahar"
          style={{
            // float: "right",
            marginBottom: 4,
            // marginRight: "-32px",
            verticalAlign: "bottom",
          }}
        />
      );
      break;
    default:
      return null;
  }
};

function App(props) {
  const [lang, setLang] = React.useState("RO");
  const [menuCat, setMenuCat] = React.useState("Food");
  const [showImages, setShowImages] = React.useState(false);
  const languages = { RO: "Română", EN: "English" };
  const menuIcons = {
    Food: FoodIcon,
    Drinks: DrinksIcon,
    Beer: BeerIcon,
  };

  var groupCats = Object.groupBy(
    props.db[menuCat].filter((el) => el.lang === lang),
    ({ categoryID }) => categoryID
  );

  const queryParameters = new URLSearchParams(window.location.search);
  const print = queryParameters.has("print");

  const unit = menuCat == "Food" ? "gr" : "ml";

  // console.log(props.db[menuCat]);
  // console.log(lang, translations);
  // console.log(print);
  // console.log(groupCats);

  return (
    <Container maxWidth="lg">
      {/* Fabs */}
      <Box
        className="menuFab"
        sx={{
          position: "fixed",
          bottom: 32,
          right: 32,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Fab
          color="secondary"
          aria-label={languages[lang]}
          title={languages[lang]}
          onClick={() => setLang(lang === "RO" ? "EN" : "RO")}
        >
          {/* <LangIcon /> */}
          <Flag lang={lang} />
        </Fab>
        <Divider sx={{ my: 2 }} />
        {Object.keys(props.db).map((btnCat) => {
          const currentCat = btnCat == menuCat;
          const Icon = menuIcons[btnCat];

          return (
            <Fab
              color={currentCat ? "secondary" : "warning"}
              variant={currentCat ? "contained" : "outlined"}
              disabled={currentCat}
              aria-label={btnCat}
              title={btnCat}
              onClick={() => setMenuCat(btnCat)}
              sx={{ mb: 2 }}
              key={btnCat}
            >
              <Icon />
              {/* <Translations lang={lang} text={btnCat} /> */}
            </Fab>
          );
        })}
        <Divider sx={{ mb: 2 }} />
        <Fab
          color="warning"
          aria-label="add"
          onClick={() => setShowImages(!showImages)}
        >
          {showImages ? <ImageHideIcon /> : <ImageIcon />}
        </Fab>
        {print && (
          <>
            {" "}
            <Divider sx={{ my: 2 }} />
            <Fab
              color="warning"
              aria-label="add"
              onClick={() => window.print()}
            >
              <PrintIcon />{" "}
            </Fab>
          </>
        )}
      </Box>

      <Box className="menuContent" sx={{ my: 4 }}>
        <Typography
          variant="h4"
          component="h1"
          textAlign="center"
          sx={{
            mb: 2,
          }}
        >
          {lang === "RO" ? (
            <>
              <Translations lang={lang} text={"Menu"} />{" "}
              <Translations lang={lang} text={menuCat} />
            </>
          ) : (
            <>
              <Translations lang={lang} text={menuCat} />{" "}
              <Translations lang={lang} text={"Menu"} />
            </>
          )}
        </Typography>

        {/* <TopNav
          categories={Object.keys(props.db)}
          menuCat={menuCat}
          setMenuCat={setMenuCat}
        /> */}

        {Object.keys(groupCats).map((catID) => {
          // console.log((catID === 12 || catID === 31) && catID);
          // console.log(catID === "12" && catID);
          return (
            <Box
              className={
                "menuCategory" + (menuCat === "Beer" ? " menuBeer" : "")
              }
              sx={{
                display: "flex",
                flexFlow: "row wrap",
                flexGrow: 2,
                columnGap: "20px",
                // columnCount: 2,
                // columnFill: "balance",
              }}
              key={catID}
            >
              {(catID === "4" ||
                catID === "12" ||
                catID === "23" ||
                catID === "31") && <div className="pageSpacer">&nbsp;</div>}
              <Category catID={catID} />

              {groupCats[catID]
                .filter((el) => el.lang === lang)
                .map((data) => {
                  // console.log(JSON.parse(data.publish));
                  if (JSON.parse(data.publish)) {
                    return (
                      <Card
                        className="menuItem"
                        sx={{
                          flex:
                            menuCat === "Beer"
                              ? "1 1 100%"
                              : "0 0 calc(50% - 10px)",
                          mb: 2,
                          // borderColor: "#F47525",
                        }}
                        // elevation={1}
                        variant="outlined"
                        key={data.id}
                      >
                        {data.image && showImages && (
                          <CardMedia
                            component="img"
                            height="384"
                            image={"/images/" + data.image}
                            alt={data.name}
                          />
                        )}
                        <CardContent sx={{ pb: "16px !important" }}>
                          {/* <p>{data.id}</p> */}
                          {menuCat === "Beer" ? (
                            <Box>
                              <Grid container spacing={2}>
                                <Grid item sm={6}>
                                  <Typography
                                    variant="h6"
                                    component="div"
                                    sx={{ mb: 0.5, pr: 4 }}
                                  >
                                    {data.name} <br />
                                    <small className="tipBere">
                                      {data.type}
                                    </small>
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    component="p"
                                    sx={{
                                      fontSize: ".825rem",
                                      lineHeight: 1.25,
                                    }}
                                  >
                                    {data.description}
                                  </Typography>
                                </Grid>
                                <Grid item sm={6}>
                                  <TableContainer
                                    component={Paper}
                                    elevation={0}
                                    className="beerPriceTable"
                                    sx={
                                      {
                                        // mt: 2,
                                        // minWidth: 650
                                      }
                                    }
                                  >
                                    <Table aria-label="simple table">
                                      <TableHead>
                                        <TableRow>
                                          {data.quantity
                                            .split(",")
                                            .map((quant, index) => (
                                              <TableCell
                                                align="center"
                                                key={index}
                                              >
                                                {quant} {unit}
                                              </TableCell>
                                            ))}
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        <TableRow
                                          sx={{
                                            "&:last-child td, &:last-child th":
                                              {
                                                border: 0,
                                              },
                                          }}
                                        >
                                          {data.price
                                            .split(",")
                                            .map((price, index) => (
                                              <TableCell
                                                align="center"
                                                key={index}
                                              >
                                                {price}
                                                {price !== "-" && " lei"}
                                              </TableCell>
                                            ))}
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </Grid>
                              </Grid>
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                gap: "10px 20px",
                              }}
                            >
                              <Box>
                                <Typography
                                  variant="h6"
                                  component="div"
                                  sx={{ mb: 0.5, pr: 4 }}
                                >
                                  {data.name}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  component="p"
                                  sx={{ fontSize: ".825rem", lineHeight: 1.25 }}
                                >
                                  {data.description}
                                  {data.description && ", "}
                                  <strong>{data.quantity + unit}</strong>
                                </Typography>
                                {/* <Typography sx={{ mt: 1 }}>
                                  {data.quantity} {unit}
                                </Typography> */}
                              </Box>
                              <Box>
                                <Box textAlign="right">
                                  <DishType type={data.type} />
                                </Box>
                                <Typography
                                  variant="h6"
                                  component="div"
                                  sx={{
                                    mt: -0.5,
                                    flex: "1 0 auto",
                                    width: 64,
                                    color: "#F47525",
                                    textAlign: "right",
                                  }}
                                >
                                  {data.price}
                                  <small> lei</small>
                                </Typography>
                              </Box>
                            </Box>
                          )}
                          {/* <Typography>{data.category}</Typography> */}
                        </CardContent>
                      </Card>
                    );
                  }
                })}
            </Box>
          );
        })}
        {menuCat == "Food" && (
          <Box className="statements">
            <Divider sx={{ my: 2 }} />
            <Typography
              variant="caption"
              textAlign="right"
              component="div"
              sx={{ mt: 2 }}
            >
              * produse congelate
            </Typography>
          </Box>
        )}
        <Copyright />
      </Box>
    </Container>
  );
}

export default withGoogleSheets(["Food", "Drinks", "Beer"])(App);
